import React from "react"
import { Box, Button, Flex, Link as LinkExt, Stack } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import WufooForm from "react-wufoo-embed"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faCalendarDay,
} from "@fortawesome/pro-regular-svg-icons"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Slider from "react-slick"
import "../../../../utils/slick.css"
import "../../../../utils/slick-theme.css"

import {
  SectionHeading,
  VideoPlayer,
  ArrowLeft,
  ArrowRight,
  PageLabel,
  LabelIcon,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ProgramsListing,
  SecondaryPagesHero,
  Seo,
  SidebarLinks,
} from "../../../../components/elements"

const MobileProgram = ({ data, pageContext }) => {
  const page = data.datoCmsCancerMobileProgram
  const {
    breadcrumb: { crumbs },
  } = pageContext

  const settings = {
    adaptiveHeight: "true",
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowLeft color="white" bg="black" />,
    nextArrow: <ArrowRight color="white" bg="black" />,
  }

  return (
    <Layout>
      <Seo title={page.title} />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Container width="full">
        <Stack
          direction={["column", "column", "row"]}
          spacing={12}
          py={[9, 9, 12, 16]}
        >
          <Main width={["full", "full", "2 / 3"]}>
            {/* Content */}
            <Flex flexWrap="wrap">
              <Box width="full">
                {page.video && (
                  <Box sx={{ mb: 6 }}>
                    <VideoPlayer url={page.video.url} />
                  </Box>
                )}

                <Box
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page.contentNode.childMarkdownRemark.html,
                  }}
                />

                {page.images.length > 0 ? (
                  <Box
                    mb={6}
                    maxWidth="680px"
                    sx={{
                      ".slick-arrow": {
                        backgroundColor: "blackAlpha.600",
                      },
                    }}
                  >
                    <Slider {...settings}>
                      {page.images.map(({ gatsbyImageData, url }) => (
                        <Box
                          key={url}
                          sx={{
                            ".gatsby-image-wrapper-constrained": {
                              display: "block",
                            },
                          }}
                        >
                          <GatsbyImage image={gatsbyImageData} alt="slide" />
                        </Box>
                      ))}
                    </Slider>
                  </Box>
                ) : null}

                {pageContext.slug === "bonnies-bus" && (
                  <>
                    
                      
                      
                      <LinkExt href="https://cm2wvucimobile.neworg.com/Default.asp?PageNum=94" style={{ textDecoration: "none" }}>
                        <Button as="span" variant="outline" colorScheme="blue" mt={6}>
                          Request a Visit
                        </Button>
                      </LinkExt>
                    

                    {/* Calendar */}
                    <Section id="events">
                      <Container>
                        <Flex flexWrap="wrap" justifyItems="center">
                          <Box sx={{ width: ["full"], textAlign: "center" }}>
                            <SectionHeading>Events</SectionHeading>
                          </Box>

                          <Box width={["full", "50%"]} mx="auto">
                            <LinkExt
                              href={page.calendarUrl}
                              style={{ textDecoration: "none" }}
                            >
                              <PageLabel>
                                <LabelIcon title="Bonnie's Bus Calendar">
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    className="fa-lg"
                                  />
                                </LabelIcon>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className="fa-lg"
                                />
                              </PageLabel>
                            </LinkExt>
                          </Box>
                        </Flex>
                      </Container>
                    </Section>
                  </>
                )}

                {pageContext.slug === "lucas" && (
                  <>
                    <LinkExt href="https://cm2wvucimobile.neworg.com/Default.asp?PageNum=94" style={{ textDecoration: "none" }}>
                        <Button as="span" variant="outline" colorScheme="blue" mt={6}>
                          Request a Visit
                        </Button>
                      </LinkExt>

                    {/* Calendar */}
                    <Section id="events">
                      <Container>
                        <Flex flexWrap="wrap" justifyItems="center">
                          <Box sx={{ width: ["full"], textAlign: "center" }}>
                            <SectionHeading>Events</SectionHeading>
                          </Box>

                          <Box width={["full", "50%"]} mx="auto">
                            <LinkExt
                              href={page.calendarUrl}
                              style={{ textDecoration: "none" }}
                            >
                              <PageLabel>
                                <LabelIcon title="LUCAS Calendar">
                                  <FontAwesomeIcon
                                    icon={faCalendarDay}
                                    className="fa-lg"
                                  />
                                </LabelIcon>
                                <FontAwesomeIcon
                                  icon={faChevronRight}
                                  className="fa-lg"
                                />
                              </PageLabel>
                            </LinkExt>
                          </Box>
                        </Flex>
                      </Container>
                    </Section>
                  </>
                )}

                {pageContext.slug === "lung-cancer-screening-program" && (
                  <Box mt={12} mx={-1}>
                    <WufooForm
                      userName="wvuhealthcare"
                      formHash="zvsa5780j5rb59"
                    />
                  </Box>
                )}

                  {pageContext.slug === "lucas" && (
                  <Box mt={12} mx={-1}>
                    <WufooForm
                      userName="wvuhealthcare"
                      formHash="m10jfnpj0sb1oo6"
                    />
                  </Box>
                )}
              </Box>
            </Flex>
          </Main>

          <Box
            as="aside"
            id="sidebar"
            bg="lightgray"
            width={["full", "full", 1 / 3]}
          >
            <Box p={[3, 3, 6]}>
              <Box as="h3">A Closer Look</Box>
              <SidebarLinks page={page} />
              <ProgramsListing />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Layout>
  )
}

export default MobileProgram

export const query = graphql`
  query mobileProgramPageQuery($slug: String!) {
    datoCmsCancerMobileProgram(slug: { eq: $slug }) {
      id
      calendarUrl
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(imgixParams: { fit: "crop", crop: "focalpoint" })
      }
      meta {
        status
      }
      linksNode {
        childMarkdownRemark {
          html
        }
      }
      video {
        url
      }
      images {
        gatsbyImageData(
          layout: CONSTRAINED
          imgixParams: { fit: "crop", crop: "focalpoint" }
        )
        url
      }
      seo {
        description
        title
        image {
          url
        }
      }
      title
      slug
    }
  }
`
